import { useMutation } from "@tanstack/react-query";
import produce from "immer";
import { WritableDraft } from "immer/dist/internal";
import { useState } from "react";
import { generatePath, Navigate } from "react-router-dom";

import {
  PutProcurementsBodyRoutesItem,
  FactoryType,
  ProductPurpose,
  ProductType,
  PutProcurements200,
} from "src/app/apis/model";
// import { getPdfApi, GetRoutePdfError } from "src/app/apis/pdf-api";
import {
  GetUserProcurementApiError,
  PutUserProcurementApiError,
  UserProcurementApi,
} from "src/app/apis/user-procurement-api/userProcurementApi";
import {
  PutUserTempProcurementApiError,
  UserTempProcurementApi,
} from "src/app/apis/user-temp-procurement-api/userTempProcurementApi";
import { UserProcurementAmountInputTemplate } from "src/app/components/templates";
import { messages } from "src/app/configs";
import { ProcurementAmount } from "src/app/domains";
import { useConfirmationDialog } from "src/app/hooks";
import { useDefaultYear, useYearDropdown } from "src/app/hooks/useYearDropdown";
import { AuthState } from "src/app/models";
import { paths } from "src/app/navigation/paths";
import { exhaustiveSwitchCase } from "src/lib/apis";
import { useAuthState } from "src/lib/components/providers/AuthStateProvider";
import { useCustomSnackbar } from "src/lib/components/providers/SnackbarProvider";
import { useAsyncEffect } from "src/lib/hooks";
// import { DownloadUtil } from "src/lib/utils/downloadUtil";
import { parseNumber } from "src/lib/utils/numberUtil";
import { isNotEmpty } from "src/lib/utils/validationUtil";

export type ProcurementsForm = {
  version: number;
  userFactories: UserFactoriesForm[];
};

export type UserFactoriesForm = {
  factoryId: string;
  factoryName: string;
  routes: RouteForm[];
};

export type RouteForm = {
  routeId: string;
  routeName: string;
  productType: ProductType;
  productName: string;
  productShortName: string;
  amount: AmountField;
  supplierFactories: SupplierFactoryForm[];
};

export type SupplierFactoryForm = {
  factoryId?: string;
  factoryType: FactoryType;
  factoryNameForOthers?: string;
  companyId?: string;
  companyName?: string;
  productName: string;
  productPurpose?: ProductPurpose;
};

export type AmountField = {
  value: string;
  error?: boolean;
};

// APIのレスポンスをフォームに合わせて型変換
export const convertProcurementsApiToForm = (
  res: PutProcurements200
): ProcurementsForm => {
  return {
    version: res.version,
    userFactories: res.userFactories.map((userFactory) => {
      return {
        factoryId: userFactory.factoryId,
        factoryName: userFactory.factoryName,
        routes: userFactory.routes.map((route) => {
          return {
            routeId: route.routeId,
            routeName: route.routeName,
            productType: route.productType,
            productName: route.productName,
            productShortName: route.productShortName,
            amount: {
              value: route.amount === undefined ? "" : route.amount?.toString(),
            },
            supplierFactories: route.supplierFactories.map(
              (supplierFactory) => {
                return {
                  factoryId: supplierFactory.factoryId,
                  factoryType: supplierFactory.factoryType,
                  factoryNameForOthers: supplierFactory.factoryNameForOthers,
                  companyId: supplierFactory.companyId,
                  companyName: supplierFactory.companyName,
                  productName: supplierFactory.productName,
                  productPurpose: supplierFactory.productPurpose,
                };
              }
            ),
          };
        }),
      };
    }),
  };
};

// 入力バリデーション
export const validateProcurementForm = (
  procurements: ProcurementsForm
): { isValid: boolean; procurements: ProcurementsForm } => {
  let isValid = true;
  const validateField = (
    field: WritableDraft<AmountField>,
    validator: (value: string) => boolean,
    isRequired: boolean
  ) => {
    switch (isRequired) {
      case true:
        const validateRequired = isNotEmpty();
        field.error = !validateRequired(field.value) || !validator(field.value);
        break;
      case false:
        field.error = !validator(field.value);
        break;
    }
    if (field.error) {
      isValid = false;
    }
  };

  return {
    procurements: produce(procurements, (procurements) => {
      procurements.userFactories.forEach((userFactory) => {
        userFactory.routes.forEach((route) => {
          validateField(route.amount, ProcurementAmount.Rule, true);
        });
      });
    }),
    isValid,
  };
};

export const UserProcurementAmountInputPage: React.FC = () => {
  const snackbar = useCustomSnackbar();
  const yearDropdownProps = useYearDropdown();
  const [, setAuthState] = useAuthState<AuthState>();
  const [hasError, setHasError] = useState(false);

  // 調達枚数入力フォームの情報を保持するstate
  const [form, setForm] = useState<ProcurementsForm | undefined>(undefined);

  // 表示されている納入先工場データ数
  const [displayLength, setDisplayedLength] = useState<number>(1);

  const [cancelButtonDisplay, setCancelButtonDisplay] = useState(false);

  const loadMore = () => {
    setDisplayedLength((x) => {
      return x + 1;
    });
  };

  // 調達量一覧APIの呼び出し
  const { isProcessing } = useAsyncEffect(async () => {
    const userProcurementApi = new UserProcurementApi();
    if (yearDropdownProps?.year === undefined) {
      return;
    }
    try {
      const res = await userProcurementApi.getUserProcurement({
        year: yearDropdownProps.year.toFormat("yyyy"),
      });
      setCancelButtonDisplay(!Boolean(res.isTemporary));
      setForm(convertProcurementsApiToForm(res));

      // まず初めに1ユーザー工場分だけ表示
      setDisplayedLength(1);
      return res;
    } catch (err) {
      setHasError(true);

      if (!(err instanceof GetUserProcurementApiError)) {
        snackbar.error(messages.common.unknown);
        return;
      }
      switch (err.type) {
        case "not-authenticated":
          setAuthState(undefined);
          snackbar.error(messages.common.sessionTimeout, {
            preventDuplicate: true,
          });
          return;
        case "network":
          snackbar.error(messages.common.network);

          return;
        case "unknown":
          snackbar.error(messages.common.unknown);

          return;
        default:
          throw exhaustiveSwitchCase(err.type);
      }
    }
  }, [yearDropdownProps?.year]);

  const { mutate: onSubmit, isLoading: isSubmitting } = useMutation(
    async () => {
      const userProcurementApi = new UserProcurementApi();
      if (yearDropdownProps === undefined || form === undefined) {
        return;
      }

      // 調達量更新API実行前にバリデーションチェックする
      const { isValid, procurements } = validateProcurementForm(form);
      setForm(procurements);
      if (!isValid) {
        snackbar.error(
          messages.userProcurementAmount.registration.validationError
        );
        return;
      }

      // 複数納入先工場のrouteを結合してAPIの引数とする
      let apiReqRoutes: PutProcurementsBodyRoutesItem[] = [];
      form.userFactories.forEach((procurementForm) => {
        const apiReqRoute = procurementForm.routes.map((route) => {
          return {
            routeId: route.routeId,
            amount: parseNumber(route.amount.value),
          };
        });
        apiReqRoutes = apiReqRoutes.concat(apiReqRoute);
      });

      try {
        // 調達量更新APIの呼び出し
        const res = await userProcurementApi.putUserProcurement({
          year: yearDropdownProps.year.toFormat("yyyy"),
          routes: apiReqRoutes,
          version: form.version,
        });
        // まず初めに1ユーザー工場分だけ表示
        setDisplayedLength(1);

        // 「一時保存」ボタンを非活性に、「登録取消」ボタンを表示する。
        setCancelButtonDisplay(true);

        snackbar.success(messages.userProcurementAmount.registration.success);
        setForm(convertProcurementsApiToForm(res));
      } catch (err) {
        if (!(err instanceof PutUserProcurementApiError)) {
          snackbar.error(messages.common.unknown);
          return;
        }
        switch (err.type) {
          case "conflict":
            snackbar.error(
              messages.userProcurementAmount.registration.conflict
            );
            return;
          case "not-authenticated":
            setAuthState(undefined);
            snackbar.error(messages.common.sessionTimeout, {
              preventDuplicate: true,
            });
            return;
          case "network":
            snackbar.error(messages.common.network);
            return;
          case "unknown":
            snackbar.error(messages.common.unknown);
            return;
          default:
            throw exhaustiveSwitchCase(err.type);
        }
      }
    }
  );

  const { mutate: onTempSubmit, isLoading: isTempSubmitting } = useMutation(
    async () => {
      const userTempProcurementApi = new UserTempProcurementApi();
      if (yearDropdownProps === undefined || form === undefined) {
        return;
      }

      // ユーザ調達量一時保存API実行前にバリデーションチェックする
      const { isValid, procurements } = validateProcurementForm(form);
      setForm(procurements);
      if (!isValid) {
        snackbar.error(
          messages.userProcurementAmount.registration.validationError
        );
        return;
      }

      // 複数納入先工場のrouteを結合してAPIの引数とする
      let apiReqRoutes: PutProcurementsBodyRoutesItem[] = [];
      form.userFactories.forEach((procurementForm) => {
        const apiReqRoute = procurementForm.routes.map((route) => {
          return {
            routeId: route.routeId,
            amount: parseNumber(route.amount.value),
          };
        });
        apiReqRoutes = apiReqRoutes.concat(apiReqRoute);
      });

      try {
        // 調達量一時保存APIの呼び出し
        const res = await userTempProcurementApi.putUserTempProcurement({
          year: yearDropdownProps.year.toFormat("yyyy"),
          routes: apiReqRoutes,
          version: form.version,
        });
        // まず初めに1ユーザー工場分だけ表示
        setDisplayedLength(1);

        snackbar.success(messages.userProcurementAmount.save.success);
        setForm(convertProcurementsApiToForm(res));
      } catch (err) {
        if (!(err instanceof PutUserTempProcurementApiError)) {
          snackbar.error(messages.common.unknown);
          return;
        }
        switch (err.type) {
          case "conflict":
            snackbar.error(messages.ghgInput.save.conflict);
            return;
          case "not-authenticated":
            setAuthState(undefined);
            snackbar.error(messages.common.sessionTimeout, {
              preventDuplicate: true,
            });
            return;
          case "network":
            snackbar.error(messages.common.network);
            return;
          case "unknown":
            snackbar.error(messages.common.unknown);
            return;
          default:
            throw exhaustiveSwitchCase(err.type);
        }
      }
    }
  );

  const { mutate: onCancelSubmit, isLoading: isCancelSubmitting } = useMutation(
    async () => {
      const userTempProcurementApi = new UserTempProcurementApi();
      if (yearDropdownProps === undefined || form === undefined) {
        return;
      }

      // 複数納入先工場のrouteを結合してAPIの引数とする
      let apiReqRoutes: PutProcurementsBodyRoutesItem[] = [];
      form.userFactories.forEach((procurementForm) => {
        const apiReqRoute = procurementForm.routes.map((route) => {
          return {
            routeId: route.routeId,
            amount: parseNumber(route.amount.value),
          };
        });
        apiReqRoutes = apiReqRoutes.concat(apiReqRoute);
      });

      try {
        // 調達量一時保存APIの呼び出し
        const res = await userTempProcurementApi.putUserTempProcurement({
          year: yearDropdownProps.year.toFormat("yyyy"),
          routes: apiReqRoutes,
          version: form.version,
        });
        // まず初めに1ユーザー工場分だけ表示
        setDisplayedLength(1);

        // 「一時保存」ボタンを活性に、「登録取消」ボタンを非表示に、「登録」ボタンを表示する。
        setCancelButtonDisplay(false);

        snackbar.success(messages.userProcurementAmount.unRegistration.success);
        setForm(convertProcurementsApiToForm(res));
      } catch (err) {
        if (!(err instanceof PutUserTempProcurementApiError)) {
          snackbar.error(messages.common.unknown);
          return;
        }
        switch (err.type) {
          case "conflict":
            snackbar.error(
              messages.userProcurementAmount.registration.conflict
            );
            return;
          case "not-authenticated":
            setAuthState(undefined);
            snackbar.error(messages.common.sessionTimeout, {
              preventDuplicate: true,
            });
            return;
          case "network":
            snackbar.error(messages.common.network);
            return;
          case "unknown":
            snackbar.error(messages.common.unknown);
            return;
          default:
            throw exhaustiveSwitchCase(err.type);
        }
      }
    }
  );

  /** 確定取消の確認ダイアログ */
  const {
    dialogProps: cancelSubmitDialogProps,
    openDialog: openCancelSubmitDialog,
  } = useConfirmationDialog(
    messages.userProcurementAmount.unRegistration.confirm,
    onCancelSubmit
  );

  const isLoading =
    isSubmitting || isProcessing || isTempSubmitting || isCancelSubmitting;
  const isEditable = !isLoading;

  const [defaultYear] = useDefaultYear();
  if (yearDropdownProps === undefined) {
    // URLが不正のとき
    return (
      <Navigate
        to={generatePath(paths.user.procurement.year, {
          year: defaultYear.toFormat("yyyy"),
        })}
      />
    );
  }

  return (
    <UserProcurementAmountInputTemplate
      form={form}
      setForm={setForm}
      isProcessing={isLoading}
      // isDownloading={isDownloading}
      isEditable={isEditable}
      hasError={hasError}
      yearDropdownProps={yearDropdownProps}
      onSubmit={onSubmit}
      onTempSubmit={onTempSubmit}
      onCancelSubmit={openCancelSubmitDialog}
      onCancelSubmitDialogProps={cancelSubmitDialogProps}
      // getPdf={getPdf}
      loadMore={loadMore}
      displayLength={displayLength}
      cancelButtonDisplay={cancelButtonDisplay}
    />
  );
};
