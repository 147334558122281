import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import chroma from "chroma-js";

import { LargeButton, NestedCard } from "src/app/components/atoms";
import { YearDropdownProps } from "src/app/components/molecules";
import {
  DashBoardCardItem,
  SingleBarChart,
} from "src/app/components/organisms";
import {
  CardPropsForChart,
  CardPropsForTotal,
} from "src/app/components/templates";
import { AppTheme, theme } from "src/app/configs";
import cardBoardIcon from "src/app/images/cardBoardEmission.png";
import cardBoardIcon2x from "src/app/images/cardBoardEmission@2x.png";
import factoryIcon from "src/app/images/factoryEmission.png";
import factoryIcon2x from "src/app/images/factoryEmission@2x.png";
import { formatEmission } from "src/app/utils/computeForVisuals";
import { FlexBox } from "src/lib/components/atoms";

export type DashBoardCardProps = {
  category: "category1" | "category4";
  yearDropdownProps: YearDropdownProps;
  isProcessing: boolean;
  totalEmissionCardProps: CardPropsForTotal;
  byStandardChartCardProps: CardPropsForChart;
  byFactoryChartCardProps: CardPropsForChart;
};

const useStyles = makeStyles<AppTheme, DashBoardCardProps>((theme) => ({
  card: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  typography: {
    color: theme.palette.primary.contrastText,
  },
  emissionText: {
    fontSize: "40px",
    fontWeight: "bold",
  },
}));

export const DashBoardCard: React.FC<DashBoardCardProps> = (props) => {
  const classes = useStyles(props);
  const isCategory1 = props.category === "category1";

  return (
    <NestedCard
      backgroundColor={
        isCategory1
          ? theme.palette.cate1ForUserCompanyBackground
          : theme.palette.cate4ForUserCompanyBackground
      }
      className={classes.card}
    >
      <FlexBox alignItems="center" flexDirection="column">
        <FlexBox
          justifyContent="center"
          alignItems="center"
          sx={{
            minWidth: 160,
            minHeight: 40,
            borderRadius: 50,
            backgroundColor:
              props.category === "category1"
                ? theme.palette.category1Badge
                : theme.palette.category4Badge,
          }}
        >
          <Typography className={classes.typography} variant="h3">
            {isCategory1 ? "カテゴリ1" : "カテゴリ4"}
          </Typography>
        </FlexBox>
        <DashBoardCardItem className={classes.card} title="年間GHG排出量">
          <FlexBox
            justifyContent="center"
            position="relative"
            sx={{
              backgroundColor:
                props.category === "category1"
                  ? chroma(theme.palette.cate1ForUserCompanyBackground)
                      .alpha(0.7)
                      .hex()
                  : chroma(theme.palette.cate4ForUserCompanyBackground)
                      .alpha(0.7)
                      .hex(),
              marginTop: theme.spacing(3),
              padding: theme.spacing(2),
              borderRadius: "5px",
            }}
          >
            <Typography className={classes.emissionText}>
              {props.totalEmissionCardProps.data === 0
                ? "-"
                : formatEmission(props.totalEmissionCardProps.data)}
            </Typography>
            <Box
              position="absolute"
              bottom="8px"
              right="8px"
              color={theme.palette.text.disabled}
            >
              <Typography variant="h5" color="inherit">
                t-CO2eq/年
              </Typography>
            </Box>
          </FlexBox>
          <FlexBox justifyContent="center" mt={3}>
            <LargeButton
              label="詳しく見る"
              variant="contained"
              color="primary"
              onClick={props.totalEmissionCardProps.onClick}
            />
          </FlexBox>
        </DashBoardCardItem>
        <DashBoardCardItem
          className={classes.card}
          titleIconProps={{
            alt: "cardBoardIcon",
            src: cardBoardIcon,
            src2x: cardBoardIcon2x,
          }}
          title="段ボールケース規格別排出量"
        >
          {!props.isProcessing &&
            (props.byStandardChartCardProps.data.length !== 0 ? (
              <SingleBarChart
                data={props.byStandardChartCardProps.data.slice(0, 8)}
                unit="(t-CO2eq/年)"
                barColor={
                  isCategory1
                    ? [theme.palette.category1Badge]
                    : [theme.palette.category4Badge]
                }
                chartType="sub"
              />
            ) : (
              <Box display="flex" width="100%" justifyContent="center">
                <Typography variant="h4">データがありません</Typography>
              </Box>
            ))}
          <FlexBox justifyContent="center" mt={3}>
            <LargeButton
              label="詳しく見る"
              variant="contained"
              color="primary"
              onClick={props.byStandardChartCardProps.onClick}
            />
          </FlexBox>
        </DashBoardCardItem>
        <DashBoardCardItem
          className={classes.card}
          titleIconProps={{
            alt: "factoryIcon",
            src: factoryIcon,
            src2x: factoryIcon2x,
          }}
          title="自社工場ごとの総排出量"
        >
          {!props.isProcessing &&
            (props.byFactoryChartCardProps.data.length !== 0 ? (
              <SingleBarChart
                data={props.byFactoryChartCardProps.data.slice(0, 8)}
                unit="(t-CO2eq/年)"
                barColor={
                  isCategory1
                    ? [theme.palette.category1Badge]
                    : [theme.palette.category4Badge]
                }
                chartType="sub"
              />
            ) : (
              <Box display="flex" width="100%" justifyContent="center">
                <Typography variant="h4">データがありません</Typography>
              </Box>
            ))}
          <FlexBox justifyContent="center" mt={3}>
            <LargeButton
              label="詳しく見る"
              variant="contained"
              color="primary"
              onClick={props.byFactoryChartCardProps.onClick}
            />
          </FlexBox>
        </DashBoardCardItem>
      </FlexBox>
    </NestedCard>
  );
};
